import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import PublicPage from '../../common/containers/PublicPage';
import Content from '../../common/containers/Content';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import Panel from '../../common/containers/Panel';
import AdvisoryModal from '../../editor/components/AdvisoryModal';
import AvisoGhostwriting from '../../common/AvisoGhostwriting';

class AdvisoryCoursesPage extends Component {
  render() {
    const { i18n } = this.props;
    const aviso = <AvisoGhostwriting />;
    return (
      <PublicPage title={i18n.t('Cursos e Assessoria')}>
        <Content>
          <Row>
            <Col md={10} mdOffset={1}>
              <Panel title="Atendimento Individualizado">
                <h4>
                  Tenha um consultor acadêmico acompanhando a elaboração de seus
                  trabalhos. O FastFormat dispõe de uma equipe multidisciplinar
                  que pode lhe orientar quanto à escrita científica e construção
                  de monografias, artigos, dissertações, teses, projetos de
                  pesquisa, relatórios técnico-científico, etc.
                </h4>

                <p>
                  <a
                    onClick={() => window.showAdvisoryModal()}
                    className="btn btn-primary btn-lg"
                  >
                    Solicitar atendimento
                  </a>
                </p>

                {aviso}
                <AdvisoryModal />
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col md={10} mdOffset={1}>
              <Panel title="Curso Planejamento e Elaboração de Projeto de Pesquisa">
                <h4>
                  Indicado para alunos de graduação que estão desenvolvendo o
                  projeto de pesquisa e buscam esclarecer dúvidas sobre como
                  definir o tema de pesquisa, problemática, objetivos, e
                  construir uma boa fundamentação teórica, além dos demais
                  aspectos normativos e estruturais exigidos pelas instituições
                  de ensino e norma vigente.
                </h4>

                <p>
                  <a
                    target="_blank"
                    href="https://go.hotmart.com/B41835074L"
                    className="btn btn-primary btn-lg"
                  >
                    Adquira o curso
                  </a>
                </p>

                {aviso}
              </Panel>
            </Col>
          </Row>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(AdvisoryCoursesPage);
